@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

* {
  box-sizing : border-box;
  margin:0;
  padding:0;
}

.App {
  text-align: center;
  background-color: #282c34;
  height: 100vh;
  width: 100vw;
  display: flex;
  margin:0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: 'Roboto', sans-serif;
}

